<template>
  <div class="card">
    <add-actuality @newsAdded="addNews" />
    <a-table
      :loading="tableLeading"
      :rowKey="'_id'"
      :columns="columns"
      :data-source="data"
      :pagination="true"
      :scroll="{ x: 'max-content' }"
    >
      <template slot="expandedRowRender" slot-scope="record">
        <label class="font-weight-bold"
          >{{ $t("actualite.description") }} :
        </label>
        <div v-html="record.desc"></div>
        <label
          v-if="record.students || record.classrooms || record.levels"
          class="font-weight-bold mr-2"
          >{{ $t("actualite.specificFor") }} :
        </label>
        <span v-if="record.students">
          <a-tag
            v-for="student in record.students"
            :key="student"
            color="green"
            >{{ getStudentFullname(student) }}</a-tag
          >
        </span>
        <span v-else-if="record.classrooms">
          <a-tag
            v-for="classroom in record.classrooms"
            :key="classroom"
            color="green"
            >{{ getClassroomName(classroom) }}</a-tag
          >
        </span>
        <span v-else-if="record.levels">
          <a-tag v-for="level in record.levels" :key="level" color="green">{{
            $t("niveau.niveau" + level)
          }}</a-tag>
        </span>
      </template>
      <template
        v-for="col in [
          'target',
          'likes',
          'photos',
          'createdAt',
          'category',
          'specified',
        ]"
        :slot="col"
        slot-scope="text, record"
      >
        <div v-if="col == 'category'" :key="col">
          <a-tag color="purple">{{ text }}</a-tag>
        </div>
        <div v-if="col == 'target'" :key="col">
          <div v-for="target in text" :key="target">
            <a-tag color="blue" class="m-1">{{ target }}</a-tag>
          </div>
        </div>
        <div v-if="col == 'likes' || col == 'photos'" :key="col">
          <template> {{ text.length }} </template>
        </div>
        <div v-if="col == 'createdAt'" :key="col">
          {{ text }}
        </div>
        <div v-if="col == 'specified'" :key="col">
          <a-tag
            v-if="!record.students && !record.levels && !record.classrooms"
            color="blue"
            class="m-1"
            >{{ $t("all.non") }}</a-tag
          >
          <a-tag v-else color="green" class="m-1">{{ $t("all.oui") }}</a-tag>
        </div>
      </template>
      <span slot="operation" slot-scope="text, record">
        <span>
          <a-button
            type="primary"
            @click="() => $router.push('/actuality/' + record._id)"
          >
            <a-icon type="edit" />{{ $t("action.consulter") }}
          </a-button>
        </span>
        <span class="ml-2">
          <a-button :disabled="editingKey !== ''" type="danger">
            <a-popconfirm
              :title="$t('requis.supp')"
              @confirm="() => supp(record._id)"
            >
              <a>{{ $t("action.supprimer") }}</a>
            </a-popconfirm>
          </a-button>
        </span>
      </span>
    </a-table>
    <!-- UPDATE DRAWER -->
    <a-drawer
      v-if="selectedClassRoom"
      :title="'Modifier l\'emplois pour ' + selectedClassRoom.name"
      :width="720"
      :visible="updateDrawer"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose('update')"
    >
      <a-form :form="form" layout="vertical">
        <a-row :gutter="16">
          <span class="p-1"
            >{{ $t("actualite.trimestre1") }}
            <a-tag
              v-if="selectedClassRoom.emplois && selectedClassRoom.emplois.sem1"
              color="green"
            >
              <a
                :href="settings.base_url + '/' + selectedClassRoom.emplois.sem1"
                target="_blank"
                rel="noopener noreferrer"
                >{{ $t("action.voir") }}</a
              >
            </a-tag>
          </span>
          <a-col :span="24">
            <a-upload-dragger
              class="p-1"
              v-decorator="[
                'sem1',
                {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
              :multiple="true"
              :before-upload="beforeUpload"
              :file-list="fileList1"
              :customRequest="addToSem1"
              :remove="handleClose"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="file-image" />
              </p>
              <p class="ant-upload-text">
                {{ $t("etablissement.clickOrDragToUpload") }}
              </p>
            </a-upload-dragger>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <span class="p-1"
            >{{ $t("actualite.trimestre2") }}
            <a-tag
              v-if="selectedClassRoom.emplois && selectedClassRoom.emplois.sem2"
              color="green"
            >
              <a
                :href="settings.base_url + '/' + selectedClassRoom.emplois.sem2"
                target="_blank"
                :v-if="selectedClassRoom.emplois.sem2"
                rel="noopener noreferrer"
                >{{ $t("action.voir") }}</a
              >
            </a-tag></span
          >
          <a-col :span="24">
            <a-upload-dragger
              class="p-1"
              v-decorator="[
                'sem2',
                {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
              :multiple="false"
              :before-upload="beforeUpload"
              :file-list="fileList2"
              :customRequest="addToSem2"
              :remove="handleClose"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="file-image" />
              </p>
              <p class="ant-upload-text">
                {{ $t("etablissement.clickOrDragToUpload") }}
              </p>
            </a-upload-dragger>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <span class="p-2"
            >{{ $t("actualite.trimestre3") }}
            <a-tag
              v-if="selectedClassRoom.emplois && selectedClassRoom.emplois.sem3"
              color="green"
            >
              <a
                :href="settings.base_url + '/' + selectedClassRoom.emplois.sem3"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ $t("action.voi") }}
              </a>
            </a-tag></span
          >
          <a-col :span="24">
            <a-upload-dragger
              class="p-1"
              v-decorator="[
                'sem3',
                {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
              :multiple="false"
              :before-upload="beforeUpload"
              :file-list="fileList3"
              :customRequest="addToSem3"
              :remove="handleClose"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="file-image" />
              </p>
              <p class="ant-upload-text">
                {{ $t("etablissement.clickOrDragToUpload") }}
              </p>
            </a-upload-dragger>
          </a-col>
        </a-row>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="onClose('update')">
          {{ $t("action.annuler") }}
        </a-button>
        <a-button type="primary" @click="handleUpdate"> Modifier </a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import addActuality from "./components/addActuality.vue";
import { mapState } from "vuex";
var publicationData = [];
export default {
  components: { addActuality },
  name: "publications",
  computed: mapState(["settings"]),
  component: {
    addActuality: addActuality,
  },
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "acutalityForm" });
  },
  async created() {
    this.getClassRooms();

    apiClient
      .post("/students/filter", {
        query: {
          status: "active",
        },
      })
      .then(({ data }) => {
        this.studentList = data;
      });

    apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
          },
        },
      })
      .then((res) => (this.classes = res.data));
  },
  data() {
    return {
      photo: null,
      updateDrawer: false,
      studentList: [],
      classes: [],
      columns: [
        {
          title: this.$t("actualite.titre"),
          dataIndex: "title",
          key: "title",
          scopedSlots: { customRender: "title" },
          sorter: (a, b) => a.title.length - b.title.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("actualite.categorie"),
          dataIndex: "category",
          key: "category",
          scopedSlots: { customRender: "category" },
          sorter: (a, b) => a.categroy.length - b.category.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("actualite.cibles"),
          dataIndex: "target",
          key: "target",
          scopedSlots: { customRender: "target" },
          sorter: (a, b) => a.target.length - b.target.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("actualite.jaimes"),
          dataIndex: "likes",
          key: "likes",
          scopedSlots: { customRender: "likes" },
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("actualite.photos"),
          dataIndex: "photos",
          key: "photos",
          scopedSlots: { customRender: "photos" },
        },
        {
          title: this.$t("actualite.specified"),
          dataIndex: "specified",
          key: "specified",
          scopedSlots: { customRender: "specified" },
        },
        {
          title: this.$t("actualite.dateCreation"),
          dataIndex: "createdAt",
          key: "createdAt",
          scopedSlots: { customRender: "createdAt" },
        },
        {
          title: this.$t("action.actions"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableLeading: true,
      visibleModal: false,
      form: this.$form.createForm(this),
      data: null,
      cacheData: null,
      loading: false,
      editingKey: "",
    };
  },
  methods: {
    getStudentFullname(id) {
      const student = this.studentList.find((s) => s._id == id);
      if (student) return student.firstName + " " + student.lastName;
    },
    getClassroomName(id) {
      const classe = this.classes.find((s) => s._id == id);
      if (classe) return classe.name;
    },
    async getClassRooms() {
      await apiClient
        .post("/actuality/all/filter", {
          query: {},
          aggregation: {
            $project: {
              __v: 0,
              updatedAt: 0,
            },
          },
        })
        .then((res) => {
          publicationData = [...res.data];
          publicationData = publicationData.map((item) => ({
            ...item,
            createdAt: moment(item.createdAt).format("DD/MM/YYYY HH:mm"),
          }));
          this.data = publicationData;
          this.cacheData = publicationData.map((item) => ({ ...item }));
          this.tableLeading = false;
        })
        .catch((e) => this.$message.error(this.$t("error.aucClasse")));
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    addClass(e) {
      this.loading = true;
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          apiClient
            .put("/actuality", { data: values })
            .then((res) => {
              res.data.createdAt = moment(this.res.data.createdAt).format(
                "DD/MM/YYYY HH:mm"
              );
              this.data.push(res.data);
              this.$message.success(this.$t("success.pubAjout"));
              this.handleCancel();
            })
            .catch((e) => this.$message.error(this.$t("error.impoPubAjout")));
        }
      });
      this.loading = false;
    },
    supp(key) {
      apiClient
        .delete("/actuality/" + key)
        .then(() => {
          this.$message.success(this.$t("success.pubSupp"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item._id !== key);
        })
        .catch(() => this.$message.error(this.$t("success.autorisationSupp")));
    },
    addNews(data) {
      data.createdAt = moment(data.createdAt).format("DD/MM/YYYY HH:mm");
      this.data = [data, ...this.data];
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
>
